.ProjectPane {
    height: 75vh;
    width: 25vw;
}

.ProjectScreenshot img{
    width: 100%;
}

ul {
    list-style-type: none;
}

li {
    list-style: square;
    color: #333;
}