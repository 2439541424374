.Background {
    z-index: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
}

.ChrisTack {
    z-index: 1;
    background-color: white;
    background-image: url('../images/brand-color.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100vw;
    min-height: 100vh;
    color: white;
}

@media screen and (max-width: 600px) {
    .ChrisTack {
        height: 40vh;
    }
}