.NavbarPane {
    z-index: 100;
    margin: 0 auto;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: #333;
    display: block;
}

.NavContainer {
    margin: auto;
    color: white;
    width: 80%;
    display: flex;
    justify-content: space-between;
    min-height: 64px;
    align-items: center;
    font-weight: 700;
}

.Brand h3{
    position: relative;
    float: left;
    font-size: 1.4em;
    font-weight: bolder;
    line-height: 0;
    padding: 0;
}

.Brand {
    padding: 1.2em 0 0 0;
    margin: 0;
    align-items: center;
    margin-block-start: .1em;
    vertical-align: middle;
}

h6 {
    line-height: 0;
    padding: 0;
    margin: 2em 0;
}

.NavLinks {
    position: relative;
    display: inline-block;
}

a {
    padding: 1.45em;
    color: white;
    text-decoration: none;
}

a:hover {
    background-color: rgb(240, 240, 240);
}