.AboutPane {
    background-color: #EA5F79;
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
}

.AboutDescription {
    font-size: 4em;
}

image {
    align-items: center;
}
