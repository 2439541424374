.App {
  text-align: center;
}

.flex-container {
  display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: column wrap;
	flex-flow: column wrap;
}

.flex-item {
  -webkit-flex: 1 0 auto;
	flex: 1 0 auto;
}

.App-link {
  color: #61dafb;
}

.MyLogo {
  size: cover;
  background-image: url('./images/ChrisTack.png');
  background-color: #333;
}

.PageContent {
  padding-top: 71px;
}